import React from 'react';
import styled, {css} from "styled-components";
import variable from "./../../style/var.module.scss"


export const H24Styled = styled.h3`
  font-size: 24px;
  line-height: 36px;


  @media (max-width: 668px) {
    font-size: 20px;
    line-height: 32px;
  }
  
  
`

export const H24 = ({children, ...rest}) => <H24Styled {...rest}>{children}</H24Styled>
