import React, {useEffect, useRef, useState} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Home from "./components/Home";
import AboutUs from "./components/AboutUs";
import Services from "./components/Services";
import Assets from "./components/Assets";
import Partners from "./components/Partners";
import Contacts from "./components/Contacts";
import Circle from "./components/ui/Circle";
import {Element, scroller} from 'react-scroll'
import {Nav} from "./components/Header/Nav";

const nav = [
    "home",
    "aboutUs",
    "services",
    "assets",
    "partners",
    "contacts"
]


const App = () => {
    const [activeLink, setActiveLink] = useState(0)
    const [fixedNav, setFixedNav] = useState(2)
    const ref = useRef([])

    const scrollTo = (name) => {
        scroller.scrollTo(name, {
            duration: 1000,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: fixedNav === 2 ? -123 : fixedNav === 1 ? -107 : fixedNav === 0 && -87
        })
    }



    useEffect(() => {
        window.addEventListener('scroll', () => {
            for (let i = 0; i < ref.current.length; i++) {
                if (window.scrollY >= (ref.current[i].offsetTop - 123)) {
                    setActiveLink(i)
                }
            }
        })
        window.addEventListener("resize", (e) => {
            if (window.innerWidth <= 1112) {
                setFixedNav(1)
                if (window.innerWidth <= 668) {
                    setFixedNav(0)
                }
            } else {
                setFixedNav(2)
            }

        })
    }, [])

    useEffect(() => {
        AOS.init({
            duration: 800,
            once: true
        })
    }, [])

    return <div style={{overflow: "hidden"}}>
        <Nav nav={nav} scrollTo={scrollTo} activeLink={activeLink}/>
        <Element name="home">
            <div ref={e => ref.current.push(e)}>
                <Home scrollTo={scrollTo}/>
            </div>
        </Element>
        <Element name="aboutUs">
            <div ref={e => ref.current.push(e)}>
                <AboutUs/>
            </div>
        </Element>
        <Element name="services">
            <div ref={e => ref.current.push(e)}>
                <Services scrollTo={scrollTo}/>
            </div>
        </Element>
        <Element name="assets">
            <div ref={e => ref.current.push(e)}>
                <Assets/>
            </div>
        </Element>
        <Element name="partners">
            <div ref={e => ref.current.push(e)}>
                <Partners/>
            </div>
        </Element>
        <Element name="contacts">
            <div ref={e => ref.current.push(e)}>
                <Contacts/>
            </div>
        </Element>
        <Circle nav={nav} scrollTo={scrollTo} activeLink={activeLink}/>
    </div>
}

export default App;
