import React, {useState} from "react";
import styled from "styled-components";
import {Container} from "./ui/Container";
import assetsImg from "./../img/assets.png"
import {H36} from "./ui/H36";
import variable from "./../style/var.module.scss"
import {H24} from "./ui/H24";
import logo1 from "./../img/logo/logo1.png"
import logo2 from "./../img/logo/logo5.png"
import logo3 from "./../img/logo/logo8.png"
import logo4 from "./../img/logo/logo2.png"
import logo5 from "./../img/logo/logo3.png"
import logo6 from "./../img/logo/logo6.png"
import logo7 from "./../img/logo/logo9.png"
import logo8 from "./../img/logo/logo11.png"
import icon1 from "./../img/icons/1.svg"
import icon2 from "./../img/icons/2.svg"
import icon3 from "./../img/icons/3.svg"
import icon4 from "./../img/icons/4.svg"
import tm1 from "./../img/tm/tm1.png"
import tm2 from "./../img/tm/tm2.png"
import tm3 from "./../img/tm/tm3.png"
import tm4 from "./../img/tm/tm4.png"
import tm5 from "./../img/tm/tm5.png"
import tm6 from "./../img/tm/tm6.png"
import tm7 from "./../img/tm/tm7.png"
import tm8 from "./../img/tm/tm8.png"
import tm9 from "./../img/tm/tm9.png"
import tm10 from "./../img/tm/tm10.png"
import tm11 from "./../img/tm/tm11.png"
import {useTranslation} from "react-i18next";

const AssetsStyled = styled.div`
  border-top: 1px solid ${variable.GOLD_COLOR};
  background: url(${assetsImg}) no-repeat center center / cover;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: ${variable.BACKGROUND_BLACK_TRANSPARENT};
  }

  > div {
    position: relative;
    z-index: 2;
    text-align: center;

    > h2 {
      text-align: center;
    }

    > span {
      margin-top: 40px;
      display: inline-flex;
      align-items: center;
      grid-gap: 50px;
      flex-wrap: wrap;
      
      @media(max-width: 668px) {
        grid-gap: 30px;

        > button {
          width: 100%;
        }
      }

      > button {
        padding: 15px 20px;
        background: ${variable.GOLD_COLOR};
        cursor: pointer;
        box-shadow: 10px -10px 0 1px ${variable.GOLD_COLOR};
        background: #000;
        color: #fff;
        border: 1px solid ${variable.GOLD_COLOR};;
        transition: all .3s ease;

        &:nth-child(1) {
          box-shadow: 10px -10px 0 1px ${variable.GOLD_COLOR};
        }

        &:nth-child(2) {
          box-shadow: -10px -10px 0 1px ${variable.GOLD_COLOR};
        }

        &:active {
          box-shadow: 0 0 0 1px ${variable.GOLD_COLOR};
          transition: all .3s ease;
        }
      }
    }

    > div, nav {
      margin-top: 60px;

      @media(max-width: 668px) {
        margin-top: 30px;
      }
    }

    > div {
      display: flex;
      justify-content: space-around;
      
      @media(max-width: 668px) {
        flex-wrap: wrap;
      }
      

      > div {
        padding: 30px;
        border-bottom: 1px solid ${variable.GOLD_COLOR};;
        border-left: 1px solid ${variable.GOLD_COLOR};;

        &:nth-child(2) {
          > div {
            > img {
              &:last-child {
                height: 50px;
              }
            }
          }
        }

        > div {
          margin-top: 40px;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          align-items: center;
          flex-wrap: wrap;
          grid-gap: 50px;
          
          @media(max-width: 1146px) {
            grid-template-columns: 1fr;
          }

          > img {
            justify-self: center;
            height: 70px
          }

          
        }
      }
    }

    > nav {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 30px;
      margin-top: 130px;

      @media(max-width: 668px) {
       margin-top: 110px;
      }
      
      @media(max-width: 1051px) {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 110px;
      }

      @media(max-width: 668px) {
        grid-template-columns: 1fr;
        grid-row-gap: 110px;
      }

      > div {
        background: #000;
        padding: 30px;
        border-bottom: 1px solid ${variable.GOLD_COLOR};;

        > img {
          margin-top: -100px;
        }

        > h1 {
          margin-top: 25px;
          padding-bottom: 10px;
        }

        > p {
          margin-top: 15px;
          font-size: 16px;
          line-height: 24px;
          color: #ffffffad;
        }
      }
    }
  }

`

const Assets = ({}) => {
    const [isPage, setIsPage] = useState(false)
    const {t} = useTranslation("global")


    return <AssetsStyled>
        <Container pTop>
            <H36>{t("assets.name")}</H36>
            <span>
                <button style={{opacity: isPage ? 0.5 : 1}}
                        onClick={() => setIsPage(false)}>{t("assets.assetsTwo.name")}</button>
                <button style={{opacity: !isPage ? 0.5 : 1}}
                        onClick={() => setIsPage(true)}>{t("assets.assetsOne.name")}</button>
            </span>
            {isPage
                ? <div>
                    <div
                        data-aos="fade-up"
                    >
                        <H24>{t("assets.assetsOne.descOne")}</H24>
                        <div>
                            {[
                              tm1,
                              tm2,
                              tm3,
                              tm4,
                              tm5,
                              tm6,
                              tm7,
                              tm8,
                              tm9,
                              tm10,
                              tm11
                              ].map((el, idx) => <img key={idx} src={el} alt=""/>)}
                        </div>
                    </div>
                    <div
                        data-aos="fade-up"
                        data-aos-duration="1100"
                    >
                        <H24>{t("assets.assetsOne.descTwo")}</H24>
                        <div>
                            {[logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8].map((el, idx) => <img key={idx} src={el} alt=""/>)}
                        </div>
                    </div>
                </div>
                : <nav>
                    {[{icon: icon1, lang: 2}, {icon: icon2, lang: 3}, {icon: icon3, lang: 2}, {
                        icon: icon4,
                        lang: 3
                    }].map((el, idx) => <div
                        data-aos="zoom-in-up"
                        data-aos-offset={(`${idx + 1}0`) * 9}
                        key={idx}
                    >
                        <img src={el.icon} alt=""/>
                        <h1>{t(`assets.assetsTwo.data.${idx}.title`)}</h1>
                        {new Array(el.lang).fill('').map((_, i) =>
                            <p key={i}>{t(`assets.assetsTwo.data.${idx}.desc.${i}`)}</p>)}
                    </div>)}
                </nav>}
        </Container>
    </AssetsStyled>
}

export default Assets;