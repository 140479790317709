import React from 'react';
import styled from "styled-components";
import {Container} from "./ui/Container";
import {useTranslation} from "react-i18next";
import imgAbout from "./../img/about.png"
import variable from "./../style/var.module.scss"
import {P20} from "./ui/P20";

const AboutUsStyled = styled.div`
  background: url(${imgAbout}) no-repeat center center / cover;
  position: relative;
  border-top: 1px solid ${variable.GOLD_COLOR};
  

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;    
    background: ${variable.BACKGROUND_BLACK_TRANSPARENT};
  }

  > div {
    position: relative;
    z-index: 2;

    > p {
      margin-top: 30px;
      text-align: center;

      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
`

const AboutUs = () => {
    const {t} = useTranslation("global")


    return <AboutUsStyled>
        <Container pTop>
            {new Array(4).fill('').map((_, idx) => <P20 key={idx} data-aos="fade-up">{t(`about.${idx + 1}`)}</P20>)}
        </Container>
    </AboutUsStyled>
}

export default AboutUs;