import React from 'react';
import styled, {css} from "styled-components";


export const P20Styled = styled.p`
  font-size: 20px;
  line-height: 32px;

  @media (max-width: 668px) {
    font-size: 18px;
    line-height: 30px;
  }
`

export const P20 = ({children, ...rest}) => <P20Styled {...rest}>{children}</P20Styled>
