import React, {useState} from "react";
import styled from "styled-components";
import contacts from "./../img/contacts.png"
import {Container} from "./ui/Container";
import {H36} from "./ui/H36";
import location from "./../img/icons/loca.svg"
import telNo from "./../img/icons/telno.svg"
import message from "./../img/icons/message.svg"
import {H24} from "./ui/H24";
import {useTranslation} from "react-i18next";
import Modal from 'react-awesome-modal';
import {IoCheckmarkDoneCircleOutline} from "@react-icons/all-files/io5/IoCheckmarkDoneCircleOutline";
import {P20} from "./ui/P20";
import variable from "./../style/var.module.scss"


const ContactsStyled = styled.div`
  border-top: 1px solid ${variable.GOLD_COLOR};
  background: url(${contacts}) no-repeat center center / cover;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: ${variable.BACKGROUND_BLACK_TRANSPARENT};
  }

  > div {
    padding-bottom: 30px;
    position: relative;
    z-index: 2;

    > div {
      margin-top: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      grid-gap: 30px;
      
      @media(max-width: 1077px) {
        flex-wrap: wrap;;
      }

      @media(max-width: 668px) {
        margin-top: 30px;
      }
      
      

      > form {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 10px;
        width: 600px;
        padding: 30px;
        background: #000;
        border-left: 1px solid ${variable.GOLD_COLOR};;
        border-top: 1px solid ${variable.GOLD_COLOR};;

        @media(max-width: 1218px) {
          max-width: 600px;
          width: 450px;
        }

        @media(max-width: 1077px) {
          max-width: 100%;
          width: 100%;
        }

        > input {
          background: none;
          border: none;
          border-bottom: 1px solid ${variable.GOLD_COLOR};;
          padding: 25px 0;
          outline: none;
          &::placeholder {
            color: #fff;
          }
        }

        > textarea {
          background: none;
          border: none;
          border-bottom: 1px solid ${variable.GOLD_COLOR};;
          padding: 25px 0;
          outline: none;
          height: 200px;
          resize: none;
          &::placeholder {
            color: #fff;
          }
        }
        
        > button {
          background: none;
          padding: 15px 20px;
          cursor: pointer;
          box-shadow: 10px -10px 0 1px #f4bc00;
          background: #000;
          color: #fff;
          border: 1px solid #F4BC00;
          margin-top: 30px;

          &:active {
            box-shadow: 0 0 0 1px ${variable.GOLD_COLOR};
            transition: all .3s ease;
          }
        }
      }

      > div {
        padding: 30px;
        background: #000;
        max-width: 500px;
        border-right: 1px solid ${variable.GOLD_COLOR};;
        border-top: 1px solid ${variable.GOLD_COLOR};;
        @media(max-width: 1077px) {
          margin: 0 auto;
        }

        > div {
          margin-top: 30px;

          > a {
            text-decoration: none;
            margin-top: 30px;
            display: flex;
            align-items: center;
            grid-gap: 20px;

            > p {
              color: #ffffffad;
              font-size: 18px;
            }

            > img {
              width: 40px;
            }
          }
        }
      }
    }

    > p {
      text-align: center;
      font-size: 14px;
      margin-top: 100px;
    }
  }
`
const FormStyledModal = styled.div`
  border: 1px solid ${variable.GOLD_COLOR};;
  background: #000;
  padding: 30px;
  text-align: center;
  > svg {
    font-size: 80px;
    color: ${variable.GOLD_COLOR};;
    > path {
      color: ${variable.GOLD_COLOR};;
    }
  }
  > p {
    margin-top: 10px;
    max-width: 400px;
    color: #ffffffad;
  }
`


const Contacts = ({}) => {
    const {t} = useTranslation("global")
    const [formValue, setFormValue] = useState({
        fullName: '',
        email: '',
        message: ''
    })
    const [modal, setModal] = useState(false)


    const handlerSubmit = () => {
        fetch(`https://api.telegram.org/bot5126805173:AAH73-LLbOFzVhEPdc6VwbM6hdeHXtFhonA/sendMessage?chat_id=-654881780&text=${encodeURIComponent(`Полная имя: ${formValue.fullName} \nПочта: ${formValue.email} \nСообщение: ${formValue.message}`)}`)
            .then(response => response.text())
            .then(result => {
                setFormValue({
                    fullName: '',
                    email: '',
                    message: ''
                })
                setModal(true)
            })
            .catch(error => console.log('error', error));
    }

    const handlerChange = (e) => {
        const {name, value} = e.target

        setFormValue(prev => ({
            ...prev,
            [name]: value
        }))
    }


    return <>
        <ContactsStyled>
        <Container pTop
                   data-aos="fade-down"
                   data-aos-duration="1000"
        >
            <H36>{t("contacts.title")}</H36>
            <div>
                <form onSubmit={e => {
                    e.preventDefault()
                    handlerSubmit()
                }}>
                    <input
                        name="fullName"
                        type="text"
                        placeholder={t("fullName")}
                        required
                        onChange={handlerChange}
                        value={formValue.fullName}
                    />
                    <input
                        name="email"
                        type="email"
                        placeholder={t("email")}
                        required
                        onChange={handlerChange}
                        value={formValue.email}
                    />
                    <textarea
                        name="message"
                        placeholder={t("message")}
                        required
                        onChange={handlerChange}
                        value={formValue.message}

                    />
                    <button>{t("button")}</button>
                </form>
                <div>
                    <H24>{t("contacts.data.0")}</H24>
                    <div>
                        <a>
                            <img src={location} alt=""/>
                            <p>{t("contacts.data.1")}</p>
                        </a>
                        <a href="tel:+992900303030">
                            <img src={telNo} alt=""/>
                            <p>+992 900 30 30 30</p>
                        </a>
                        <a href="mailto:distribution@makolli.tj">
                            <img src={message} alt=""/>
                            <p>distribution@makolli.tj</p>
                        </a>
                    </div>
                </div>
            </div>
            <p>{t("footer")}</p>
        </Container>
    </ContactsStyled>
        <Modal
            visible={modal}
            effect="fadeInDown"
            onClickAway={() => setModal(false)}
        >
            <FormStyledModal>
                <IoCheckmarkDoneCircleOutline/>
                <P20>{t("dialog")}</P20>
            </FormStyledModal>
        </Modal>
    </>
}

export default Contacts;