import React from 'react';
import styled from "styled-components";
import {Container} from "./ui/Container";
import Img from "./../img/home.png"
import {useTranslation} from "react-i18next";
import {H60} from "./ui/H60";
import {H24} from "./ui/H24";
import {AiOutlineArrowDown} from "@react-icons/all-files/ai/AiOutlineArrowDown";
import variable from "./../style/var.module.scss"

const HomeStyled = styled.div`
  background: url(${Img}) no-repeat top center / cover;
  position: relative;
  height: 100vh;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: ${variable.BACKGROUND_BLACK_TRANSPARENT};
  }


  > div {
    position: relative;
    height: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      margin-top: 100px;
      text-align: center;

      > h1 {
        max-width: 913px;
        margin: 0 auto;
      }

      > h3 {
        max-width: 650px;
        margin: 20px auto;
      }

      > div {
        margin-top: 10px;
        display: inline-flex;
        align-items: center;
        grid-gap: 20px;

        > i {
          width: 80px;
          height: 2px;
          background: ${variable.GOLD_COLOR};
        }

        > span {
          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background: ${variable.GOLD_COLOR};
          transition: all .3s ease;
          cursor: pointer;

          &:hover {
            transform: scale(1.1);
            transition: all .3s ease;
            box-shadow: 0 0 15px ${variable.GOLD_COLOR};
          }

          > svg {
            font-size: 36px;
            color: #000;

            > path {
              color: #000;
            }

            animation: action 1s alternate infinite;

            @keyframes action {
              0% {
                transform: translateY(-2px);
              }
              100% {
                transform: translateY(3px);
              }
            }

          }


        }
      }
    }
  }
`


const Home = ({scrollTo}) => {
    const {t} = useTranslation('global')

    return <HomeStyled>
        <Container pTop>
            <div>
                <H60 data-aos="zoom-in">{t("title")}</H60>
                <H24 data-aos="fade-up">{t("about.0")}</H24>
                <div>
                    <i/>
                    <span onClick={() => scrollTo("aboutUs")}>
                        <AiOutlineArrowDown/>
                    </span>
                    <i/>
                </div>
            </div>
        </Container>
    </HomeStyled>
}

export default Home;