import React, {useEffect, useState} from 'react';
import {Container} from '../ui/Container';
import styled from 'styled-components';
import logo from './../../img/logo.png';
import variables from './../../style/var.module.scss';
import {useTranslation} from "react-i18next";
import "/node_modules/hamburgers/dist/hamburgers.css";


const NavbarStyled = styled.div`
  background: ${variables.MAIN_COLOR};
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;

  @media (max-width: 1112px) {
    padding: 11px 0;
  }


  > div {

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > a {
        display: flex;
        align-items: center;
        justify-content: center;
        > img {
          height: 85px;
          @media (max-width: 668px) {
            height: 65px;
          }
        }
      }


      > div {
        display: flex;
        align-items: center;
        grid-gap: 77px;

        > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          grid-gap: 60px;

          @media (max-width: 1224px) {
            grid-gap: 40px;
          }

          @media (max-width: 1112px) {
            display: none;
          }

          > li {
            cursor: pointer;
            transition: all .3s ease;
            height: 123px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            color: #fff;

            &:after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              height: 4px;
              background: transparent;
            }
          }

          > .activeLinkNav {
            color: ${variables.GOLD_COLOR};

            &:after {
              background: ${variables.GOLD_COLOR};
            }
          }
        }

        > nav {
          display: flex;
          align-items: center;
          grid-gap: 30px;
          position: relative;

          @media (max-width: 668px) {
            grid-gap: 20px;
          }

          > span {
            display: flex;
            align-items: center;
            grid-gap: 14px;
            color: #fff;

            > p {
              color: #fff;
              cursor: pointer;
            }
          }

          > button {
            cursor: pointer;
            background: none;
            border: none;
            outline: none;
            display: none;
            align-items: center;
            justify-content: center;
            @media (max-width: 1112px) {
              display: flex;
            }
            
            
            > span {
              pointer-events: none;
              > span {
                background: ${variables.GOLD_COLOR};
                &:after {
                  background: ${variables.GOLD_COLOR};
                }
                &:before {
                  background: ${variables.GOLD_COLOR};
                }
              }
            }
          }
          > div {
            position: absolute;
            right: 0;
            padding: 15px 25px;
            background: #000;
            border: 1px solid ${variables.GOLD_COLOR};
            margin-top: 300px;
            > li {
              line-height: 35px;
              cursor: pointer;
            }
            .activeLinkNavButton {
              color: ${variables.GOLD_COLOR};
            }
          }
        }
      }
    }
  }

`;



export const Nav = ({nav, scrollTo, activeLink}) => {
    const {t, i18n} = useTranslation('global')
    const [isModal, setIsModal] = useState(false)

    useEffect(() => {
        window.addEventListener('click', (evt) => {
            if (!evt.target.matches(`.menuBurgerLabel`)) {
                setIsModal(false)
            }
        })
    }, [])


    return <NavbarStyled>
        <Container>
            <div>
                <a href="http://distribution.makolli.tj/">
                    <img src={logo} alt=''/>
                </a>
                <div>
                    <div>
                        {nav.map((el, idx) => <li
                            className={activeLink === idx ? 'activeLinkNav' : ''}
                            key={idx}
                            onClick={() => scrollTo(el)}>{t(`nav.${idx}`)}
                        </li>)}
                    </div>
                    <nav>
                        <span>
                        <p style={{color: i18n.language === 'ru' && '#F4BC00'}}
                           onClick={() => i18n.changeLanguage('ru')}>RU</p> |
                        <p style={{color: i18n.language === 'en' && '#F4BC00'}}
                           onClick={() => i18n.changeLanguage('en')}>EN</p>
                        </span>
                        <button
                            onClick={() => setIsModal(prev => !prev)}
                            className={`menuBurgerLabel hamburger--3dy ${isModal ? "is-active" : ''}`}
                            type="button"
                        >
                          <span className="hamburger-box">
                            <span className="hamburger-inner"/>
                          </span>
                        </button>
                        {isModal &&
                        <div>
                            {nav.map((el, idx) => <li
                                className={activeLink === idx ? 'activeLinkNavButton' : ''}
                                key={idx}
                                onClick={() => scrollTo(el)}>{t(`nav.${idx}`)}
                            </li>)}
                        </div>}
                    </nav>
                </div>
            </div>
        </Container>
    </NavbarStyled>
}

