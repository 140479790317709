import React from 'react';
import styled from "styled-components";
import variable from "./../../style/var.module.scss"

const CircleStyled = styled.div`
  position: fixed;
  z-index: 999;
  top: 50%;
  right: 30px;
  transform: translate(0, -50%);
  

  @media(max-width: 900px) {
    display: none;
  }

  > div {
    width: 35px;
    height: 35px;
    border: 3px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;

    > span {
      display: block;
      width: 15px;
      height: 15px;
      background: #fff;
      border-radius: 50%;
    }
  }
  > .activeCircle {
    border-color: ${variable.GOLD_COLOR};

    > span {
      background: ${variable.GOLD_COLOR};
    }
  }
`


const Circle = ({nav, scrollTo, activeLink}) => {
    return <CircleStyled>
        {nav.map((el, idx) => <div key={idx} className={activeLink === idx ? "activeCircle" : ''}
                                   onClick={() => scrollTo(el)}>
            <span key={idx}/>
        </div>)}
    </CircleStyled>
}

export default Circle;