import React from 'react';
import styled from "styled-components";



const H60Styled = styled.h1`
  font-size: 60px;
  font-weight: 600;
  line-height: 76px;
  
  @media(max-width: 900px) {
    font-size: 46px;
    line-height: 62px;
  }

  @media (max-width: 668px) {
    font-size: 28px;
    line-height: 40px;
  }
  //
  //@media(max-width: 564px) {
  //  font-size: 28px;
  //}
`


export const H60 = ({children, ...rest}) => <H60Styled {...rest}>{children}</H60Styled>

