import React from "react";
import styled from "styled-components";
import {Container} from "./ui/Container";
import partners from "./../img/partners.jpeg"
import variable from "./../style/var.module.scss"
import logo1 from "./../img/logo/logo1.png"
import logo2 from "./../img/logo/logo2.png"
import logo3 from "./../img/logo/logo3.png"
import logo4 from "./../img/logo/logo4.png"
import logo5 from "./../img/logo/logo5.png"
import logo6 from "./../img/logo/logo6.png"
import logo7 from "./../img/logo/logo7.png"
import logo8 from "./../img/logo/logo8.png"
import logo9 from "./../img/logo/logo9.png"
import logo10 from "./../img/logo/logo10.png"
import logo11 from "./../img/logo/logo11.png"
import {H36} from "./ui/H36";
import {P20} from "./ui/P20";
import {useTranslation} from "react-i18next";

const PartnersStyled = styled.div`
  border-top: 1px solid ${variable.GOLD_COLOR};
  background: url(${partners}) no-repeat center center / cover;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: ${variable.BACKGROUND_BLACK_TRANSPARENT};
  }

  > div {
    position: relative;
    z-index: 2;

    > h2 {
      text-align: center;
    }

    > div {
      margin-top: 60px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      align-items: center;
      flex-wrap: wrap;
      grid-gap: 50px;

      @media (max-width: 1072px) {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        
      }

      > div {
        justify-self: center;
        text-align: center;

        > p {
          margin-top: 20px;
        }
      }
    }
  }
`

const lists = [
    logo1,
    logo5,
    logo8,
    logo7,
    logo3,
    logo6,
    logo2,
    logo9,
    logo11,
    logo4,
    logo10
]

const Partners = ({}) => {
    const {t} = useTranslation("global")


    return <PartnersStyled>
        <Container pTop>
            <H36>{t(`partnersTitle`)}</H36>
            <div>
                {lists.map((el, idx) => <div key={idx} data-aos="zoom-out-up">
                    <img src={el}/>
                    <P20>{t(`partners.${idx}`)}</P20>
                </div>)}
            </div>
        </Container>
    </PartnersStyled>
}

export default Partners;