import React, {useState} from "react";
import styled from "styled-components";
import {Container} from "./ui/Container";
import servicesImg from "./../img/services.png"
import variable from "./../style/var.module.scss"
import {H36} from "./ui/H36";
import {P20} from "./ui/P20";
import {useTranslation} from "react-i18next";
import img1 from "./../img/servicesImg1.jpg"
import img2 from "./../img/servicesImg2.jpg"
import {H60} from "./ui/H60";

const ServicesStyled = styled.div`
  background: url(${servicesImg}) no-repeat center center / cover;
  border-top: 1px solid ${variable.GOLD_COLOR};
  
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: ${variable.BACKGROUND_BLACK_TRANSPARENT};
  }
  > div {
    position: relative;
    z-index: 2;

    > div {
      padding-left: 100px;
      @media(max-width: 668px) {
        padding-left: 60px;
      }

      > div {
        display: grid;
        grid-template-columns: 1fr 300px;
        
        @media(max-width: 900px) {
          grid-template-columns: 1fr;
          
          > span {
            height: 300px;
            margin-top: 30px;
            padding-bottom: 10px;

            @media (max-width: 668px) {
              width: 100%;
              > div {
                width: 100%;
              }
            }
           
          }
        }

        > div {
          padding-right: 50px;

          > p {
            margin-top: 30px;
          }
        }

        > span {
          display: block;
          border: 1px solid ${variable.GOLD_COLOR};

          > div {
            height: 100%;
            width: 100%;
            position: relative;
            left: -15px;
            top: 15px;
          }
        }
      }
      
      > nav {
        margin-top: 50px;
        
        > button {
          padding: 15px 20px;
          background: ${variable.GOLD_COLOR};
          cursor: pointer;
          box-shadow: 10px -10px 0 1px ${variable.GOLD_COLOR};
          background: #000;
          color: #fff;
          border: 1px solid ${variable.GOLD_COLOR};;
          transition: all .3s ease;

          &:nth-child(1) {
            box-shadow: 10px -10px 0 1px ${variable.GOLD_COLOR};
          }

          &:active {
            box-shadow: 0 0 0 1px ${variable.GOLD_COLOR};
            transition: all .3s ease;
          }
        }
      }
      
    }

    > span {
      position: absolute;
      left: 30px;
      bottom: 0;
      top: 0;
      padding-top: 64px;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
      grid-gap: 10px;

      @media (max-width: 668px) {
        padding-top: 40px !important;
      }

      @media(max-width: 900px) {
        padding-top: 73px;
      }

      > h1 {
        color: ${variable.GOLD_COLOR};
      }

      > div {
        background: ${variable.GOLD_COLOR};
        width: 8px;
        margin: 0 auto;
      }
    }
  }
`

const Services = ({scrollTo}) => {
    const [isPage, setIsPage] = useState(true)

    const {t} = useTranslation("global")

    return <ServicesStyled>
        <Container pTop>
            <div>
                <div>
                    <div>
                        <H36 data-aos="zoom-in">{t(isPage ? "services.0" : "servicesTwo.0")}</H36>
                        {new Array(3).fill('').map((_, idx) => <P20 key={idx} data-aos="fade-up">{t(isPage ? `services.${idx + 1}` : `servicesTwo.${idx + 1}`)}</P20>)}
                    </div>
                    <span data-aos="zoom-out"><div style={{background: `url(${isPage ? img1 : img2}) no-repeat center center / cover`}}/></span>
                </div>
               <nav>
                 <button onClick={() => {
                   setIsPage(prev => !prev)
                   scrollTo("services")
                 }}>{t(! isPage ? "services.0" : "servicesTwo.0")}</button>
               </nav>
            </div>
            <span data-aos="fade-up">
                <H60>{isPage ? "01" : "02" }</H60>
                <div/>
            </span>
        </Container>

    </ServicesStyled>
}

export default Services;