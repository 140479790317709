import React from 'react';
import styled from "styled-components";


export const H36Styled = styled.h2`
  font-size: 36px;
  font-weight: 600;

  @media (max-width: 668px) {
    font-size: 28px;
    line-height: 40px;
  }
`

export const H36 = ({children, ...rest}) => <H36Styled {...rest}>{children}</H36Styled>
